import { useLocation, useParams } from 'react-router-dom';
import { CheckIcon, ClipboardCopyIcon } from '@radix-ui/react-icons';
import { useQuery } from '@tanstack/react-query';
import { getTicketQuery } from '@/api/queries';

const paypalUsername = import.meta.env.VITE_PAYPAL_USERNAME;

export const TicketDetails = () => {
  const { search } = useLocation();

  const { ticketId } = useParams<{ ticketId: string }>();
  const { data: ticket } = useQuery(getTicketQuery(ticketId ?? ''));

  if (!ticket) return null;

  const isAfterNov28 = Date.now() > 1732800000000;

  const ClipboardCopy = () => {
    const copy = () => {
      navigator.clipboard.writeText(ticket.ticketNumber);
      alert('Ticketnumber copied to clipboard!');
    };
    return (
      <button type="button" className="ml-2" onClick={() => copy()}>
        <ClipboardCopyIcon className="w-4 h-4" />
      </button>
    );
  };

  if (ticket.canceledAt) {
    return (
      <>
        <div className="bg-background/40 p-4 border border-destructive rounded">
          <h2 className="mt-2 font-bold text-warning">Ticket canceled</h2>
          <span className="text-destructive">
            Your ticket has been canceled and you cannot use it to enter the event.
          </span>
          <dl className="mt-4 [&>dd]:mb-2 [&>dt]:text-sm [&>dt]:text-foreground/80">
            <dt>Ticket number:</dt>
            <dd>{ticket.ticketNumber}</dd>
            <dt>Email:</dt>
            <dd>{ticket.email}</dd>
          </dl>
        </div>
      </>
    );
  }

  return (
    <div>
      {search.includes('success') ? (
        <>
          <h1 className="font-bold text-lg">Thank you for registering for a ticket.</h1>
          <p className="text-foreground/90 mb-6">
            You will shortly receive an email with an order confirmation. Please pay the ticket
            price in the next 48 hours or otherwise your registration might get canceled. Please
            take a look in your spam folder for emails from info@wuest-und-wild.de
          </p>
        </>
      ) : null}
      <h1 className="font-bold text-lg">Ticket Status</h1>
      {!ticket.paidAt ? (
        <>
          <h2 className="mt-2 font-bold text-warning">Payment pending</h2>
          <span className="text-destructive">No payment received / processed</span>
          <p className="bg-foreground/10 p-4 border rounded mb-4 mt-4 text-warning">
            Please send the money via PayPal to{' '}
            <a
              className="font-bold text-foreground"
              target="_blank"
              href={`https://paypal.me/${paypalUsername}/${ticket.price}`}
              rel="noreferrer">
              https://paypal.me/{paypalUsername}
            </a>
            <br />
            Message: <span className="text-foreground">{ticket.ticketNumber}</span>{' '}
            <ClipboardCopy />
          </p>
          <p className="mt-2 text-sm text-muted/80">
            Note: Sometimes it can take a while for your payment to be registered, as we manually
            have to check your payment. If you have waited a few days without any change feel free
            to contact us via
            <a className="border bg-warning/20 px-1 rounded" href="mailto:info@wuest-und-wild.de">
              info@wuest-und-wild.de
            </a>
            <br />
            Thank you for your patience {'<'}3
          </p>
        </>
      ) : null}
      <dl className="mt-4 [&>dd]:mb-2 [&>dt]:text-sm [&>dt]:text-foreground/80">
        {ticket.paidAt ? (
          <>
            <dt>Payment:</dt>
            <dd>
              <div className="flex text-success items-center">
                <CheckIcon className="mr-2" />
                Payment received / processed on{' '}
                {Intl.DateTimeFormat('de-DE').format(new Date(ticket.paidAt))}
              </div>
            </dd>
          </>
        ) : null}
        <dt>Ticket number:</dt>
        <dd>
          {ticket.ticketNumber} <ClipboardCopy />
        </dd>
        <dt>Name:</dt>
        <dd>{ticket.name}</dd>
        <dt>Email:</dt>
        <dd>{ticket.email}</dd>
        <dt>Ticket price:</dt>
        <dd>{ticket.price} EUR</dd>
      </dl>
      {isAfterNov28 ? (
        <>
          <h3 className="mt-4 text-lg font-medium">Location</h3>
          <p className="my-2">
            Stadtbahnbogen 114 Alexanderplatz (other side of ALX Späti), see image below:
          </p>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/wuest-affairs.appspot.com/o/public%2Flocation_wa.jpeg?alt=media&token=f5e4bd13-a194-4cc9-888c-ec9820de9a48"
            className="max-w-[800px] w-full rounded-md"
          />
        </>
      ) : null}
    </div>
  );
};
